import Vue from 'vue'
import VueRouter from 'vue-router'
import versionTood from '@/utils/versionUpdate'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/index/index.vue'),
    meta: { ignoreAuth: true, title: '首页' },
  },
  {
    path: '/:type/:param',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/groupBuying/GetOpenId.vue'),
    meta: { ignoreAuth: true, title: '信息提示' },
    props: route => ({ platform: route.query.platform, page: route.query.page, url_type: route.query.url_type, targetappid: route.query.target_app_id, query: route.query.query, url: route.query.url }) // 将URL参数传递给组件
  },
  {
    path: '/code',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/scan/index.vue'),
    meta: { ignoreAuth: true, title: '在线付款' },
    props: route => ({ order_no: route.query.order_no, order_id: route.query.order_id, order_type: route.query.order_type }) // 将URL参数传递给组件
  },
  {
    path: '/result',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/scan/result.vue'),
    meta: { ignoreAuth: true, title: '信息提示' },
    props: route => ({ platform: route.query.platform, page: route.query.page, url_type: route.query.url_type, targetappid: route.query.target_app_id, query: route.query.query, url: route.query.url }) // 将URL参数传递给组件
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/groupBuying/error.vue'),
    meta: { ignoreAuth: true, title: '信息提示' },
    // props: route => ({ info: route.query.info }) // 将URL参数传递给组件
  },
  // {
  //   path: '/error',
  //   component: () => import(/* webpackChunkName: "fail" */ '@/views/groupBuying/index.vue')
  // },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
    meta: { ignoreAuth: true, title: '拼团' },
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  versionTood.isNewVersion()
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router
